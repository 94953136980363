/**
 * @file @/common/enum/index.js
 * @desc 模块枚举
 */


/**
 * @enum {string} 模块名称
 */
export enum  ModelsEnum {
  /** @desc 员工子女入学 */
  EMPLOYEE_CHILD_DECLARAT= "employeechilddeclarat"
}

/**
 * 入园申请审核步骤
 */
export enum ProjectSmallStep {
  /** 待审核 */
  WAIT_EXAMINE = "1",
  /** 审核驳回 */
  EXAMINE_REJECT = "2",
  /** 待二次审核 */
  WAIT_SECOND_EXAMINE="2.5",
  /** 评审驳回 */
  REVIEW_REJECT="10",
  /** 待二次评审 */
  WAIT_SECOND_REVIEW="10.5",
}