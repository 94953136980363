<template>
  <div>
    <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="85px" label-position="top">
      <div style="padding: 0 20px">
        <div class="title">
          <div class="line"></div>
          <div class="text">拟投资项目情况</div>
        </div>
        <el-row :gutter="64">
          <el-col :span="12">
            <el-form-item label="项目名称" prop="entryName">
              <el-input v-model="saveData.entryName" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请原因" prop="reasonsForApplication">
              <el-input v-model="saveData.reasonsForApplication" placeholder="请输入" :maxlength="500" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="在瓯江口新区注册新企业或整体搬迁至瓯江口新区" prop="registerOrMove">
              <el-input v-model="saveData.registerOrMove" placeholder="请输入" :maxlength="200" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if='isGeneral'>
            <el-form-item label="项目拟用地(亩)" prop="proposedLandProject">
              <el-input  type="number" v-model.number="saveData.proposedLandProject" placeholder="请输入" :maxlength="200" :disabled="disabled">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if='isGeneral'>
            <el-form-item label="总建筑面积（㎡）" prop="totalConstructionArea">
              <el-input type="number" v-model.number="saveData.totalConstructionArea" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if='isGeneral'>
            <el-form-item label="建设内容" prop="constructionContent">
              <el-input v-model="saveData.constructionContent" placeholder="请输入" :maxlength="500" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="12" v-if='!isGeneral'>
            <el-form-item label="实到注册资金" prop="actualRegisteredCapital">
              <el-input v-model="saveData.actualRegisteredCapital" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="12" v-if='!isGeneral'>
            <el-form-item label="申请面积" prop="applicationArea">
              <el-input v-model="saveData.applicationArea" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划总投资(万元)" prop="totalProjectInvestment">
              <el-input type="number" v-model.number="saveData.totalProjectInvestment" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其中：固定资产投资(万元)" prop="investmentInFixedAssets">
              <el-input type="number" v-model.number="saveData.investmentInFixedAssets" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资金自筹数额(万元)" prop="amountFunds">
              <el-input  type="number" v-model.number="saveData.amountFunds" placeholder="请输入" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资金自筹比例（%）" prop="amountSelfRaised">
              <el-input type="number" v-model.number="saveData.amountSelfRaised" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          
          <el-col :span="12">
            <el-form-item label="其余资金筹集方式" prop="selfFinancing">
              <el-input v-model="saveData.selfFinancing" placeholder="请输入" :maxlength="50" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="!isGeneral">
            <el-form-item label="拟入驻类型" prop="proposedSettlementType">
              <el-select v-model.number="saveData.proposedSettlementType" @change="handleSelectChange">
                <!-- 1 孵化 2 中试 3 加速 -->
                <el-option :value="1" label="孵化"></el-option>
                <el-option :value="2" label="中试"></el-option>
                <el-option :value="3" label="加速"></el-option>
              </el-select>
              <!-- <el-input type="number" v-model.number="saveData.proposedSettlementType" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!isGeneral">
            <el-form-item label="拟入驻签约时间" prop="entrySigningTime">
              <el-date-picker   type="date"  v-model.number="saveData.entrySigningTime" :disabled="disabled" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!isGeneral">
            <el-form-item label="预计办公或投产时间" prop="expectedOfficeTime">
              <el-date-picker   type="date" v-model.number="saveData.expectedOfficeTime" :disabled="disabled" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>

      <div style="padding: 0 20px">
        <div class="title">
          <div class="line"></div>
          <div class="text">项目产出效益</div>
        </div>
        <el-row :gutter="64">
          <el-col :span="12">
            <el-form-item label="主要产品及年产量" prop="productsOutput">
              <el-input v-model="saveData.productsOutput" placeholder="请输入" :maxlength="200" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目达产预计总年产值(万元)" prop="totalOutput">
              <el-input type="number" v-model.number="saveData.totalOutput" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计亩均产值(万元/亩)" prop="perMuOutput">
              <el-input type="number" v-model.number="saveData.perMuOutput" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计总年销售收入(万元)" prop="totalSalesRevenue">
              <el-input type="number" v-model.number="saveData.totalSalesRevenue" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年利润总额(万元)" prop="totalAnnualProfit">
              <el-input type="number" v-model.number="saveData.totalAnnualProfit" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="销售利润率(%)" prop="profitMarginOfSales">
              <el-input type="number" v-model.number="saveData.profitMarginOfSales" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目达产预计总年税费(万元)" prop="projectFees">
              <el-input type="number" v-model.number="saveData.projectFees" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计亩均税费(万元/亩)" prop="perMuTax">
              <el-input type="number" v-model.number="saveData.perMuTax" placeholder="请输入" :maxlength="20" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          
        </el-row>
      </div>
      <div style="height: 16px; background: #f5f5f5"></div>
      <div style="padding: 0 20px 20px">
        <div class="title">
          <div class="line"></div>
          <div class="text">项目主要内容及优势特点</div>
        </div>
        <el-form-item prop="projectAdvantages">
          <el-input type="textarea" :rows="8" v-model="saveData.projectAdvantages" placeholder="请输入项目主要内容及优势特点" :disabled="disabled"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
export default {
  name: 'situation',
   watch:{
    saveData:{
      deep:true,
      handler(nv){
   
        localStorage.setItem("EDITING_FLAG",JSON.stringify(nv))
      }
    }
  },
  data() {
    return {
      saveData: {
        entryName: '',
        reasonsForApplication: '',
        registerOrMove: '',
        proposedLandProject: '',
        totalConstructionArea: '',
        constructionContent: '',
        applicationArea:'',
        actualRegisteredCapital:'',
        totalProjectInvestment: '',
        investmentInFixedAssets: '',
        amountSelfRaised: '',
        amountFunds:'', 
        selfFinancing: '',
        productsOutput: '',
        totalOutput: '',
        perMuOutput: '',
        totalSalesRevenue: '',
        totalAnnualProfit: '',
        profitMarginOfSales: '',
        projectFees: '',
        perMuTax: '',
        contentAndFeatures: '',
        proposedSettlementType:  '',
        entrySigningTime:  '',
        expectedOfficeTime:  '',
      },
      saveRules: {
        entryName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        reasonsForApplication: [{ required: false, message: '请输入申请原因', trigger: 'blur' }],
        registerOrMove: [{ required: true, message: '请输入在瓯江口新区注册新企业或整体搬迁至瓯江口新区', trigger: 'blur' }],
        proposedLandProject: [{ required: true, message: '请输入项目拟用地', trigger: 'blur' }],
        totalConstructionArea: [{ required: true, message: '请输入总建筑面积', trigger: 'blur' }],
        constructionContent: [{ required: true, message: '请输入建设内容', trigger: 'blur' }],
        applicationArea: [{ required: true, message: '请输入申请面积', trigger: 'blur' }],
        actualRegisteredCapital: [{ required: true, message: '请输入实到注册资金', trigger: 'blur' }],
        totalProjectInvestment: [{ required: true, message: '请输入计划总投资', trigger: 'blur' }],
        investmentInFixedAssets: [{ required: true, message: '请输入其中：固定资产投资', trigger: 'blur' }],
        amountSelfRaised: [{ required: true, message: '请输入资金自筹比例（%）', trigger: 'blur' }],
        amountFunds: [{ required: true, message: '请输入资金自筹数额', trigger: 'blur' }],
        selfFinancing: [{ required: true, message: '请输入其余资金筹集方式', trigger: 'blur' }],
        productsOutput: [{ required: false, message: '请输入主要产品及年产量', trigger: 'blur' }],
        totalOutput: [{ required: false, message: '请输入项目达产预计总年产值', trigger: 'blur' }],
        perMuOutput: [{ required: false, message: '请输入预计亩均产值', trigger: 'blur' }],
        totalSalesRevenue: [{ required: false, message: '请输入预计总年销售收入', trigger: 'blur' }],
        totalAnnualProfit: [{ required: false, message: '请输入年利润总额', trigger: 'blur' }],
        profitMarginOfSales: [{ required: false, message: '请输入销售利润率', trigger: 'blur' }],
        projectFees: [{ required: false, message: '请输入项目达产预计总年税费', trigger: 'blur' }],
        perMuTax: [{ required: false, message: '请输入预计亩均税费', trigger: 'blur' }],
        contentAndFeatures: [{ required: true, message: '请输入项目主要内容及优势特点', trigger: 'blur' }],
        proposedSettlementType: [{ required: true, message: '请输入拟入驻类型', trigger: 'blur' }],
        entrySigningTime: [{ required: true, message: '请输入拟入驻签约时间', trigger: 'blur' }],
        expectedOfficeTime: [{ required: true, message: '请输入预计办公或投产时间', trigger: 'blur' }],
       
      },
    };
  },
  methods: {
    handleSelectChange(val){
      console.log(`handleSelectChange`,val,typeof val)
    },
    init() {
      if (this.$store.state.projectId != null) {
        let getData = this.$route.name == 'General' ? this.$api.getProjectWillInvested : this.$api.getSafeProjectWillInvested;
        getData(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            data.expectedOfficeTime = data.expectedOfficeTime || "";
            data.expectedOfficeTime = data.expectedOfficeTime || ""
            data.proposedSettlementType  = Number(data.proposedSettlementType)
            this.saveData = data || {};
          }
        });
      }
    },
    validatoData() {
      let flage;
      this.$refs['saveForm'].validate((valid,obj) => {
        if(!valid) this.$message.error(obj[ Object.keys(obj)[0]][0].message)
        flage = valid;
      });
      if (!flage) {
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
    isGeneral(){
      return this.$route.name === 'General'
    }
  },

};
</script>

<style lang='scss' scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
</style>
