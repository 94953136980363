<template>
  <div>
    <div style="padding: 0 20px 20px">
      <div class="title">
        <div class="line"></div>
        <div class="text">经济效益分析</div>
      </div>
      <el-button type="primary" @click="add(table, info)" style=" margin-bottom: 20px" :disabled="disabled">新增</el-button>
      <el-table :data="table.concat(total)" style="width: 100%">
        <el-table-column min-width="68" label="产品名称">
          <template slot-scope="scope">
            <div v-if="scope.row.ifTotal">{{ scope.row.productName }}</div>
            <el-input v-model="scope.row.productName" :maxlength="50" v-else :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="销售类型" min-width="68">
          <template slot-scope="scope">
            <el-select v-model="scope.row.salesType" placeholder="请选择" v-if="!scope.row.ifTotal" :disabled="disabled">
              <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="年产值（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.annualValue"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="销售单价（元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.salesUnitPrice"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="销售收入（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.salesRevenue"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="原材料">
          <template slot-scope="scope">
            <el-input :disabled="disabled" type="number" v-model.number="scope.row.rawMaterial"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="燃烧动力">
          <template slot-scope="scope">
            <el-input :disabled="disabled" type="number" v-model.number="scope.row.combustionPower"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="工资定额（元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.wageQuota"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="车间经费（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.workshopFunds"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="折旧费（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.depreciationCharge"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="销售利润（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.salesProfit"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="营业费用（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.operatingExpenses"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="管理费用（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.managementFees"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="财务费用（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.financialExpenses"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="利润总额（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.totalProfit"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="税收总额（国税+地税）（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.totalTax"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="68" label="净利润（万元）">
          <template slot-scope="scope">
            <el-input type="number" :disabled="disabled" v-model.number="scope.row.netProfit"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="70">
          <template slot-scope="scope" v-if="!scope.row.ifTotal">
            <el-button type="danger" plain @click="deletes(scope.$index, table,scope.row)" v-if="scope.$index != 0" style="padding: 12px 10px" :disabled="disabled">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-form label-width="85px" label-position="top">
      <div style="padding: 0 20px">
        <el-form-item class="prepend-input">
          <el-input v-model="total1" disabled>
            <template slot="prepend">
              <div class="fl-c">
                投资利润率 =
                <div class="formula">
                  <div class="bb">总利润</div>
                  <div>总投资</div>
                </div>
                * 100%
              </div>
            </template>

          </el-input>
        </el-form-item>
        <el-form-item class="prepend-input">
          <el-input v-model="total2" disabled>
            <template slot="prepend">
              <div class="fl-c">
                销售利润率 =
                <div class="formula">
                  <div class="bb">销售利润</div>
                  <div>销售收入</div>
                </div>
                * 100%
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="prepend-input">
          <el-input v-model="total3" disabled>
            <template slot="prepend">
              <div class="fl-c">
                投资回收期 =
                <div class="formula">
                  <div class="bb">总投资额</div>
                  <div>净利润 + 折旧费</div>
                </div>
                + 建设期(月)
              </div>
            </template>
            
           
          </el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'basicInfo',
  data() {
    return {
      type: [
        { value: '1', label: '内销' },
        { value: '2', label: '外销' },
        { value: '3', label: '总销' },
      ],
      table: [
        {
          productName: '',
          salesType: '',
          annualValue: '',
          salesUnitPrice: '',
          salesRevenue: '',
          rawMaterial: '',
          combustionPower: '',
          wageQuota: '',
          workshopFunds: '',
          depreciationCharge: '',
          salesProfit: '',
          operatingExpenses: '',
          managementFees: '',
          financialExpenses: '',
          totalProfit: '',
          totalTax: '',
          netProfit: '',
        },
      ],
      info: {
        productName: '',
        salesType: '',
        annualValue: '',
        salesUnitPrice: '',
        salesRevenue: '',
        rawMaterial: '',
        combustionPower: '',
        wageQuota: '',
        workshopFunds: '',
        depreciationCharge: '',
        salesProfit: '',
        operatingExpenses: '',
        managementFees: '',
        financialExpenses: '',
        totalProfit: '',
        totalTax: '',
        netProfit: '',
      },
      total: [
        {
          productName: '合计:',
          salesType: '',
          annualValue: '',
          salesUnitPrice: '',
          salesRevenue: '',
          rawMaterial: '',
          combustionPower: '',
          wageQuota: '',
          workshopFunds: '',
          depreciationCharge: '',
          salesProfit: '',
          operatingExpenses: '',
          managementFees: '',
          financialExpenses: '',
          totalProfit: '',
          totalTax: '',
          netProfit: '',
          ifTotal: true,
        },
      ],
    };
  },
  watch: {
    table: {
      handler: function (newVal, oldVal) {
        for (const key in this.total[0]) {
          if (key !== 'ifTotal' && key != 'productName' && key != 'salesType') {
            this.total[0][key] = this.table.reduce(function (a, b, c) {
              return a + Number(b[key]);
            }, 0);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      disabled: (state) => state.formDisabled,
    }),
    total1() {
      let value = ((this.total[0]['totalProfit'] / this.total[0]['annualValue']) * 100).toFixed(2);
      return value.indexOf('NaN') > -1 ? '0%' : value + '%';
    },
    total2() {
      let value = ((this.total[0]['salesProfit'] / this.total[0]['salesRevenue']) * 100).toFixed(2);
      return value.indexOf('NaN') > -1 ? '0%' : value + '%';
    },
    total3() {
      let value = (this.total[0]['totalProfit'] / (this.total[0]['netProfit'] + this.total[0]['depreciationCharge'])).toFixed(2) + 2;
      return (value.indexOf('NaN') > -1 ? 0 : value) + '月' ;
    },
  },
  methods: {
    init() {
      if (this.$store.state.projectId != null) {
        let getData = this.$route.name == 'General' ? this.$api.getProjectBenefit : this.$api.getSafeProjectBenefit;
        getData(this.$store.state.projectId).then((res) => {
          if (res.success) {
            let data = JSON.parse(JSON.stringify(res.result));
            this.table = data.length > 0 ? data : this.table;
          }
        });
      }
    },
    add(table, obj) {
      table.push(Object.assign({}, obj));
    },
    async deletes(index, table,row,model) {
      console.log(`删除`,{index,table,row})
      if(row.id){
         await this.$confirm("确认删除?","删除",{ type:'warning' })
         if( this.$route.name == 'General'){
          await this.$api.deleteProjectBenefit(row.id);

         }else{
          await this.$api.deleteSafeBenefit(row.id);
         }
         this.$message.success("删除成功")
      }
     

      table.splice(index, 1);
    },
    validatoData() {
      let flage;
      return true
      // this.table.forEach((e) => {
      //   for (const key in this.info) {
      //     if (!e[key] && e[key] !== 0 && e[key] !== '0') {
      //       this.$message.error({
      //         message: `请将经济效益分析填写完整`,
      //         offset: 80,
      //       });
      //       flage = true;
      //       return;
      //     }
      //   }
      // });
      // if (flage) return false;
      // return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.fileBox {
  padding: 0 20px 20px;
  background-color: #fff;
  .file {
    margin-top: -5px;
    display: flex;
    font-size: 18px;
    color: #000000;
    line-height: 40px;
    margin-left: 14px;
  }
}
/deep/ .el-input__inner {
  padding: 0 5px;
}
.formula {
  text-align: center;
  min-width: 60px;
  margin: 0 10px;
  line-height: 18px;
}
</style>
